import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import StorageService, { allowedKeys } from '../services/StorageService';

const ProtectedRouter = (props) => {
  const { routers } = props
  let history = useHistory()

  const onTokenChange = (newToken) => {
    if (!newToken) {
      history.push("/login")
    }
  }

  useEffect(() => {
    StorageService.registerListener(allowedKeys.TOKEN_KEY, onTokenChange, { run1st: true });
    return () => {
      StorageService.removeListener(allowedKeys.TOKEN_KEY, onTokenChange);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      {
        routers && routers.length > 0 &&
        routers.map(router => {
          const { component, childs } = router
          if (childs && childs.length > 0) {
            return (
              childs.map(childRoute => {
                if (!childRoute.component) return null
                return (
                  <Route
                    key={childRoute.path}
                    exact={childRoute.exact || false}
                    path={childRoute.path}
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <childRoute.component model={childRoute.model} name={childRoute.name} />
                      </Suspense>
                    )}
                  />
                )
              })
            )
          }
          if (!component) {
            return null
          }
          return (
            <Route
              key={router.path}
              exact={router.exact || false}
              path={router.path}
              render={() => (
                <Suspense fallback={<Loading />}>
                  <router.component model={router.model} name={router.name} />
                </Suspense>
              )}
            />
          )
        })
      }
      <Route render={() => <div style={{ paddingTop: '30px', textAlign: "center" }}>404 Not Found</div>} />
    </Switch>
  );
}

export default ProtectedRouter;