import { ReactNode } from "react"
import clsx from 'clsx'

import styles from "./Loading.module.scss"

export interface LoadingProps {
  className?: string,
  children?: ReactNode,
  isLoading?: boolean
}

const render = (props: LoadingProps) => {
  const { className, children, isLoading } = props;
  if (!children) {
    return (
      <div className={clsx(styles.root, className && className)}>
        <img className={styles.image} src="/color-logo.svg" alt="" />
      </div>
    )
  }
  return (
    <div className={clsx(styles.wrapContent, !isLoading && styles.inActive)}>
      {
        children &&
        <div className={styles.children}>
          { children }
        </div> 
      }
      <img className={styles.image} src="/color-logo.svg" alt="" />
    </div>
  )
}

const Loading = (props: LoadingProps) => {
  return (
    <>{render(props)}</>
  )
}

export default Loading