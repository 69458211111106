import { useState } from "react"
import clsx from 'clsx'

import Logo from "../../assets/images/color-logo.svg";

import styles from "./Sidebar.module.scss"
import MenuItem from "./MenuItem";
import LogoutButton from "./LogoutButton";

export interface SidebarProps {
  className?: string,
  routers?: any
}

const Sidebar = (props: SidebarProps) => {
  const { className, routers } = props;
  const [active, setActive] = useState(true);
  const [open, setOpen] = useState(null)

  const handleArrowClick = () => {
    setActive(!active)
    const wrapper = document.getElementById("main-wrapper");
    if (active) {
      wrapper.style.paddingLeft = "70px";
    } else {
      wrapper.style.paddingLeft = "286px";
    }
  }

  const renderMenus = (items: Array<any>, sub?: boolean) => {
    return (
      <ul className={clsx(styles.list, sub && styles.subList)}>
        {
          items?.map(item =>
            item?.inSideBar &&
            <MenuItem 
              {...item} 
              key={item.path} 
              renderMenus={renderMenus} 
              open={open}
              setOpen={setOpen}
              className={styles.item}
              itemHeight={66}
              menuActive={active}
            />
          )
        }
      </ul>
    )
  }

  return (
    <div className={
      clsx(
        styles.root, 
        className && className,
        !active && styles.inactiveSidebar
      )
    }>
      <div className={styles.header}>
        <img src={Logo} alt="logo" className={styles.logo} />
        <h1 className={styles.pageName}>NextCXM</h1>
        <div className={styles.arrow} onClick={handleArrowClick} />
      </div>
      <div className={styles.body}>
        <ul className={styles.list}>
          { renderMenus(routers) }
          <LogoutButton className={styles.item} />
        </ul>
      </div>
    </div>
  )
}

export default Sidebar