import axios, { AxiosInstance } from 'axios';
import StorageService from '../services/StorageService';
import queryString from 'query-string';
import { API_URL } from '../utils/setting';

//console.log(publicRuntimeConfig.AUTH_API)
// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosAuth = axios.create({
  baseURL: API_URL,
  paramsSerializer: params => queryString.stringify(params),
});

createCheckLoginInterceptor(axiosAuth);
createConfigInterceptor(axiosAuth);

function createConfigInterceptor (ins:AxiosInstance) {
  ins.interceptors.request.use(
    config => {
      if(StorageService.token){
        config.headers['Authorization'] = `Bearer ${StorageService.token}`;
      }
      return config;
    },
    error => {
      throw error
    }
  );
}

function createCheckLoginInterceptor (ins:AxiosInstance) {
  ins.interceptors.response.use(
    async response => {
      return response
    },
    error => {
      // if(error.response.status === 401) {
      //   StorageService.profile = undefined
      //   StorageService.token = ""
      // }
      return error.response;
      
    }
  );
}

export default axiosAuth;
