import { useEffect, useState } from "react";
import CustomInput from "../../CustomInput";
import CustomSelectSearch from "../../CustomSelectSearch";
import styles from "./UpdateToken.module.scss";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import ChatService from "../../../services/ChatService";
import { useNotification } from "../../../contexts/NotificationContext";

const initState = {
  access_token: "",
  page_id: "",
  app_id: "",
  secret_key: "",
  type: "pancake",
  refresh_token: "",
};
const UpdateToken = () => {
  const [state, setState] = useState(initState);
  const history = useHistory();
  const notification = useNotification();

  const handleChangeType = ({ name, value }) => {
    setState({
      ...state,
      type: value,
    });
  };

  const handleChangeInput = ({ name, value }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleCancel = () => {
    history.push("/chat");
  };

  const handleUpdate = async () => {
    try {
      const res = await ChatService.updateToken(state);
      if (res.data.code === 1) {
        notification.success("Cập nhật token thành công");
        history.push("/chat");
      } else {
        notification.error(res.data.message);
      }
    } catch (error) {
      notification.error(error.message);
    }
  };

  useEffect(() => {
    let ignore = false;
    (async () => {
      try {
        const res = await ChatService.getToken(state.type);
        if (!ignore) {
          if (res.data.code === 1) {
            setState({
              ...state,
              ...res?.data?.data?.data,
            });
          } else {
            notification.error(res.data.message);
            setState({
              ...state,
              access_token: "",
              page_id: "",
              app_id: "",
              secret_key: "",
              refresh_token: "",
            });
          }
        }
      } catch (error) {
        notification.error("Có lỗi xảy ra");
        console.log(error);
      }
    })();
    return () => {
      ignore = true;
    };
  }, [state.type, notification]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1> Cập nhật token </h1>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.field}>
              <CustomSelectSearch
                placeholder="Chọn loại token"
                label="Loại token"
                onBlur={handleChangeType}
                onChange={handleChangeType}
                value={state.type}
                options={[
                  {
                    name: "Zalo",
                    value: "zalo",
                  },
                  {
                    name: "Facebook",
                    value: "pancake",
                  },
                ]}
              />
            </div>
            <div className={styles.field}>
              <CustomInput
                name="access_token"
                onChange={handleChangeInput}
                value={state.access_token}
                placeholder="Nhập access token"
                label="Access token"
              />
            </div>
            {state.type === "zalo" ? (
              <div>
                <div className={styles.field}>
                  <CustomInput
                    name="refresh_token"
                    onChange={handleChangeInput}
                    value={state.refresh_token}
                    label="Refresh token"
                    placeholder="Nhập refresh token"
                  />
                </div>
                <div className={styles.field}>
                  <CustomInput
                    name="app_id"
                    onChange={handleChangeInput}
                    value={state.app_id}
                    label="Id app"
                    placeholder="Nhập id app"
                  />
                </div>
                <div className={styles.field}>
                  <CustomInput
                    name="secret_key"
                    onChange={handleChangeInput}
                    value={state.secret_key}
                    label="Secret key"
                    placeholder="Nhập secret key"
                  />
                </div>
              </div>
            ) : (
              <div className={styles.field}>
                <CustomInput
                  name="page_id"
                  onChange={handleChangeInput}
                  value={state.page_id}
                  label="Id trang"
                  placeholder="Nhập id trang"
                />
              </div>
            )}

            {/* <CustomInput label='App id'/> */}
          </div>
          <div className={styles.action}>
            <Button className={styles.create} onClick={handleUpdate}>
              Cập nhật
            </Button>
            <Button
              variant="light"
              className={styles.cancel}
              onClick={handleCancel}
            >
              Hủy bỏ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateToken;
