import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

//import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import ProtectedRouter from './routers/ProtectedRoute';

//import routers from './routers/routers';
import partnerRouters from './routers/partnerRouters';
import rootRouters from './routers/rootRouters';

import LoginService from './services/Login';
import StorageService, { allowedKeys } from './services/StorageService';
import Loading from './components/Loading';

function App() {
  const [profile, setProfile] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  //let history = useHistory()

  const onTokenChange = useCallback((newToken) => {
    if (!newToken) {
      setLoading(false)
      setProfile(null)
      return
    } else {
      (async () => {
        try {
          const resp = await LoginService.getMe()
          if(resp.data?.success) {
            setProfile(resp.data?.data?.data)
          }  
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }    
      })()
    }
  }, [])

  useEffect(() => {
    StorageService.registerListener(allowedKeys.TOKEN_KEY, onTokenChange, { run1st: true });
    return () => {
      StorageService.removeListener(allowedKeys.TOKEN_KEY, onTokenChange);
    };
  }, [onTokenChange]);

  const routers = useMemo( () => {
    if(profile?.type === "partner") {
      return partnerRouters
    }
    if(profile?.type === "next-cxm") {
      return rootRouters
    }
    return []
  }, [profile]) 

  if(loading) {
    return (
      <Loading className="loading" />
    )
  }

  return (
    <Switch>
      <Route exact path="/login" render={() => <Login />} />
      <Route path="/" render={() => (            
          <div id="main-wrapper">
            {/* <Header /> */}
            <ProtectedRouter routers={routers} />
            <Sidebar routers={routers} />
          </div>
        )}
      />
    </Switch>
  );
}

export default App;
