import axiosAuth from './axiosAuth';

const Login = {
  async login(data) { 
    return axiosAuth.post("/staff/login", data)
  },
  async getMe() { 
    return axiosAuth.get("/staff/me")
  }
};

export default Login;