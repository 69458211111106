import React, { 
  createContext, 
  ReactNode,
  useEffect,
  useRef, 
} from "react";
import StorageService, { allowedKeys } from "../services/StorageService";

export const AppContext = createContext(null);

interface AppContextProps {
  children: ReactNode
}

export function AppProvider(props: AppContextProps) {
  const loginRef = useRef(false)

  const onLoginChange = () => {
    if(StorageService.token) {
      loginRef.current = true
    } else {
      loginRef.current = false
    }
  }

  useEffect( () => {
    StorageService.registerListener(allowedKeys.TOKEN_KEY, onLoginChange, { run1st: true })
    return () => {
      StorageService.removeListener(allowedKeys.TOKEN_KEY, onLoginChange);
    }
    // eslint-disable-next-line
  }, [] )

  return (
    <AppContext.Provider value={{
      loginRef
    }}>
      { props.children }
    </AppContext.Provider>
  );
}