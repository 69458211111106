import { lazy } from 'react';
import UpdateToken from '../components/Chat/UpdateToken/UpdateToken';
const KabanPage = lazy(()=> import('../pages/KabanPage/KabanPage')) ;
const Chat = lazy(()=> import('../pages/Chat/Chat'));
const PageModel = lazy(()=> import('../pages/PageModel'))
const PageModelAction = lazy(()=> import('../pages/PageModel/PageModelAction'))

const partnerRouters = [
  {
    path: "/",
    name: "Dashboard",
    icon: "grid_view",
    inSideBar: true,
    component: () => "",
    exact: true,
  },
  {
    path: "/staff-partner",
    name: "Danh sách nhân viên",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "staff-partner",
    exact: true,
  },
  {
    path: "/staff-partner/:type/:id?",
    name: "Nhân viên",
    icon: "assignment_ind",
    inSideBar: false,
    component: PageModelAction,
    model: "staff-partner",
    exact: true,
  },
  {
    path: "/manage-user",
    name: "Thông tin khách hàng",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "manage-user",
    exact: true,
  },
  {
    path: "/manage-user/:type/:id?",
    name: "Thông tin khách hàng",
    icon: "assignment_ind",
    inSideBar: false,
    component: PageModelAction,
    model: "manage-user",
    exact: true,
  },
  {
    path: "/customer",
    name: "Khách hàng",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "customer",
    exact: true,
  },
  {
    path: "/customer/:type/:id?",
    name: "Khách hàng",
    icon: "assignment_ind",
    inSideBar: false,
    component: PageModelAction,
    model: "customer",
    exact: true,
  },
  {
    path: "/customer-experience",
    name: "Trải nghiệm khách hàng",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "customer-experience",
    exact: true,
  },
  {
    path: "/segment",
    name: "Segment",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "segment",
    exact: true,
  },
  {
    path: "/segment/:type/:id?",
    name: "Segment",
    icon: "assignment_ind",
    inSideBar: false,
    component: PageModelAction,
    model: "segment",
    exact: false,
  },
  {
    path: "/import-history",
    name: "Lịch sử Import",
    icon: "history",
    inSideBar: true,
    component: PageModel,
    model: "import-histories",
    exact: true,
  },
  {
    path: "/your-boards",
    name: "Your boards",
    icon: "grid_view",
    inSideBar: true,
    component: PageModel,
    model: "your-boards",
    exact: true,
  },
  {
    path: "/your-boards/:type/:id?",
    name: "Your boards",
    icon: "grid_view",
    inSideBar: false,
    component: PageModelAction,
    model: "your-boards",
    exact: false,
  },
  {
    path: "/boards/:id?",
    component: KabanPage,
    model: "your-boards",
  },
  {
    path: "/chat",
    name: "Chat",
    icon: "contact_support",
    inSideBar: true,
    component: Chat,
    exact: true,
  },
  {
    path: "/chat/update-token",
    name: "Token",
    inSideBar: false,
    component: UpdateToken,
    exact: true,
  },
  {
    path: "/voucher",
    name: "Voucher",
    icon: "redeem",
    inSideBar: true,
    component: PageModel,
    model: "voucher",
    exact: true,
  },
  {
    path: "/voucher/:type/:id?",
    name: "Voucher",
    icon: "redeem",
    inSideBar: false,
    component: PageModelAction,
    model: "voucher",
    exact: true,
  },
  {
    path: "/manage-question",
    name: "Hỗ trợ trả lời",
    icon: "contact_support",
    inSideBar: true,
    component: PageModel,
    model: "manage-question",
    exact: true,
  },
  {
    path: "/manage-question/:type/:id?",
    name: "câu hỏi",
    icon: "contact_support",
    inSideBar: false,
    component: PageModelAction,
    model: "manage-question",
    exact: false,
  },
]

export default partnerRouters