import React, { useState } from 'react';
import clsx from 'clsx'

import styles from './snackbar.module.scss'

export interface ISnackbar {
  type?: 'open' | 'success' | 'error',
  message: string
}

const initialState = {
  type: '',
  message: ""
}

const Snackbar = React.forwardRef( (props, ref: any) => {
  const [state, setState] = useState(initialState)
  
  const openSnackBar = (message = 'Something went wrong...', type: ISnackbar["type"]) => {
    setState( prevState => ({
      ...prevState,
      message: message,
      type: type
    }))
    setTimeout(() => {
      setState(initialState);
    }, 3000);
  }
  
  ref.current = openSnackBar

  return (
    <div className ={
      clsx(
        styles.snackbar, 
        state.type === 'open' && styles.show,
        state.type === 'success' && styles.success,
        state.type === 'error' && styles.error
      )}
    >
      {state.message}
    </div>
  )
})

export default Snackbar