import React, { ReactNode, useCallback, useEffect, useState } from "react"
import clsx from 'clsx'
import SelectSearch, { fuzzySearch, SelectedOption, SelectedOptionValue, SelectSearchOption } from "react-select-search"

import styles from "./CustomSelectSearch.module.scss"

export interface SelectProps {
  className?: string,
  options: SelectSearchOption[] | [],
  valueKey?: string,
  nameKey?: string,
  label?: string,
  placeholder?: string,
  name?: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  multiple?: boolean,
  valueType?: 'value' | 'option',
  service?: Function,
  serviceDataKey?: string,
  required?: boolean,
  canSearch?: boolean,
  disabled?: boolean,
  errorMsg?: string,
  icon?: ReactNode,
  listenerKey?: string,
  listenerValue?: string | number
}

export interface IOnchange {
  (
    selectedValue?: SelectedOptionValue | SelectedOptionValue[],
    selectedOption?: SelectedOption | SelectedOption[]
  )
}

const CustomSelectSearch = (props: SelectProps) => {
  const {
    className,
    options = [],
    valueKey = "value",
    nameKey = "name",
    label,
    placeholder,
    name,
    value = "",
    onChange,
    onBlur,
    multiple = false,
    valueType = 'value', //'value' | 'option'
    service,
    serviceDataKey = 'data',
    required,
    // error,
    // errorMessage="Mục này là bắt buộc",
    errorMsg,
    canSearch = true,
    disabled,
    icon,
    listenerValue,
    listenerKey,
  } = props

  const [optionList, setOptionList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [blurCount, setBlurCount] = useState(0)

  const handleSelect: IOnchange = (selectedValue, selectedOption) => {
    if (onChange) {
      if (valueType === 'value') {
        onChange({ name: name, value: selectedValue })
        return
      }
      if (valueType === 'option') {
        onChange({ name: name, value: selectedOption })
        return
      }
    }
  }

  const handleBlur = () => {
    setBlurCount(prev => prev + 1)
  }

  useEffect(() => {
    if (options && options.length > 0) {
      const dataList = options.map(item => ({ value: item[valueKey], name: item[nameKey] }))
      setOptionList(dataList)
    }
  }, [options, valueKey, nameKey])

  useEffect(() => {
    if (blurCount > 0) {
      onBlur({ name, value })
    }
    // eslint-disable-next-line
  }, [blurCount, name, value])

  useEffect(() => {
    if (service) {
      const params = {};
      if (listenerKey && !listenerValue) {
        setIsLoading(false)
        return
      }
      if (listenerKey && listenerValue) {
        params[listenerKey] = listenerValue
      }
      (async () => {
        try {
          if (listenerValue) {
            setIsLoading(true)
          }
          const resp = await service(params)
          if (resp?.data?.success) {
            const dataList = resp?.data?.data?.[serviceDataKey]
            if (dataList && dataList.length > 0) {
              const list = dataList.map(item => ({ value: item[valueKey], name: item[nameKey] }))
              setOptionList(list)
            }
          } else {
            console.log(resp?.data?.message)
          }
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }
      })();
    } else {
      setIsLoading(false)
    }
  }, [service, serviceDataKey, valueKey, nameKey, listenerKey, listenerValue])

  const customStyles = useCallback((key) => {
    return styles[key]
  }, [])

  return (
    <div className={clsx(styles.root, className && className)}>
      <div
        className={clsx(
          styles.inputWrapper,
          errorMsg && styles.invalidInputWrapper,
        )}
        onBlur={handleBlur}
      >
        {
          !isLoading && service &&
          <SelectSearch
            className={customStyles}
            search={canSearch}
            options={optionList}
            placeholder={placeholder}
            value={value}
            onChange={handleSelect}
            multiple={multiple}
            closeOnSelect={!multiple}
            printOptions="on-focus"
            filterOptions={fuzzySearch}
            disabled={disabled}
          />
        }
        {
          !isLoading && !service &&
          <SelectSearch
            className={customStyles}
            search={canSearch}
            options={optionList}
            placeholder={placeholder}
            value={value}
            onChange={handleSelect}
            multiple={multiple}
            closeOnSelect={!multiple}
            printOptions="on-focus"
            filterOptions={fuzzySearch}
            disabled={disabled}
          />
        }
        {
          label &&
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        }
        <div className={styles.line}></div>
        <div className={styles.activeLine}></div>
        {
          icon &&
          <div className={styles.icon}>
            {icon}
          </div>
        }
      </div>
      {
        errorMsg &&
        <span className={styles.error}>{errorMsg}</span>
      }
    </div>
  )
}

export default React.memo(CustomSelectSearch)