import React, { 
  createContext,
  useContext, 
  useRef, 
} from "react";
import Snackbar from "../components/Snackbar";

export interface INotification {
  children: React.ReactNode
}

export interface ISnackbarRef {
  (message: string, type: 'open' | 'success' | 'error'): void
}

export interface INotificationContext {
  open: (message: string) => void,
  success: (message: string) => void,
  error: (message: string) => void
}

export const NotificationContext = createContext<INotificationContext>(null);
export const useNotification = () => useContext(NotificationContext);

export const NotificationContextProvider = (props: INotification) => {
  const snackbarRef = useRef<ISnackbarRef>()

  const onOpen = (message: string) => {
    snackbarRef.current(message, "open")
  }
  const onSuccess = (message: string) => {
    snackbarRef.current(message, "success")
  }
  const onError = (message: string) => {
    snackbarRef.current(message, "error")
  }

  return (
    <NotificationContext.Provider value={{
        open: onOpen,
        success: onSuccess,
        error: onError
      }} 
    >
      <Snackbar ref={snackbarRef} />
      {props.children}
    </NotificationContext.Provider>
  );
}
