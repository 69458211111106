const validateField = ({validateSchema, name, value, errors}) => {
  let valid = true;
  const newErrors = {...errors};

  if (validateSchema[name]?.required?.value && !value) {
    valid = false;
    newErrors[name] = validateSchema[name]?.required?.message;
  }
  if (value && validateSchema[name]?.isEmail && !validateSchema[name]?.isEmail?.isValid(value)) {
    valid = false;
    newErrors[name] = validateSchema[name]?.isEmail?.message;
  }
  if (value && validateSchema[name]?.isDate && !validateSchema[name]?.isDate?.isValid(value)) {
    valid = false;
    newErrors[name] = validateSchema[name]?.isDate?.message;
  }
  if (value && validateSchema[name]?.min && !validateSchema[name]?.min?.isValid(value)) {
    valid = false;
    newErrors[name] = validateSchema[name]?.min?.message;
  }
  if (value && validateSchema[name]?.max && !validateSchema[name]?.max?.isValid(value)) {
    valid = false;
    newErrors[name] = validateSchema[name]?.max?.message;
  }
  return {
    valid,
    newErrors
  }
}

export default validateField