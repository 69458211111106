import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom'; 
import clsx from "clsx";

import CustomInput from '../../components/CustomInput';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';

import Logo from "../../assets/images/logo.svg"
import StorageService from '../../services/StorageService';
import LoginService from '../../services/Login';
import useForm from '../../hooks/useForm';

import styles from './Login.module.scss';
import { isEmail } from '../../utils/validateReg';
import Alert from '../../components/Alert';

const initialValues = {
  email: "",
  password: ""
}

const validateSchema = {
  email: {
    required: {
      value: true,
      message: "This field is required."
    },
    isEmail: {
      isValid: (value) => isEmail(value),
      message: "Wrong email format."
    },
    min: {
      isValid: (value) => value.length > 6,
      message: "The email needs to be at least 6 characters or more."
    },
    max: {
      isValid: (value) => value.length <= 50,
      message: "The email needs to be no more than 50 characters."
    },
  },
  password: {
    required: {
      value: true,
      message: "This field is required."
    },
    min: {
      isValid: (value) => value.length >= 6,
      message: "The password needs to be at least 6 characters or more."
    },
    max: {
      isValid: (value) => value.length < 50,
      message: "The password needs to be no more than 50 characters."
    },
  },
} 

const Login = () => {
  const [rememberCheck, setRememberCheck] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory()
  
  const { 
    values, 
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({ 
    initialValues, 
    validateSchema,
  });
  
  useEffect(() => {
    if (StorageService.token) {
      history.push('/');
    }
  }, [history]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await LoginService.login({
        email: values.email,
        password: values.password
      })   
      if (res.data?.success) { 
        
        StorageService.token = { value: res.data?.data?.token, expires: rememberCheck ? 365 : undefined }
        // StorageService.profile = res.data?.data?.user
      } else {
        setError(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    } finally {
      if(StorageService.token) {
        history.push("/")
        return
      }
      setLoading(false);
    }
  }
  
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img src={Logo} alt="page logo" className={styles.logo} />
        <h1 className={styles.pageTitle}>Welcome to our CRM</h1>
        <p className={styles.pageDescription}>
          Enter your details to proceed further
        </p>
      </div>
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx(styles.header, styles.desktopHeader)}>
            <h1 className={styles.pageTitle}>
              {`Welcome to our CRM. 
              Sign In to see latest updates.`}
            </h1>
            <p className={styles.pageDescription}>
              Enter your details to proceed further
            </p>
          </div>
          {
            error &&
            <div className={styles.alert}>
              <Alert severity="error">{error}</Alert>
            </div>
          }
          <div className={styles.formControl}>
            <CustomInput 
              name="email"
              placeholder="Enter your email"
              label="Email"
              value={values["email"]}
              errorMsg={errors["email"]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={loading}
              icon="drafts"
            />
          </div>
          <div className={styles.formControl}>
            <CustomInput 
              name="password"
              placeholder="Enter your password"
              label="Password"
              value={values["password"]}
              errorMsg={errors["password"]}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              disabled={loading}
              icon="lock"
            />
          </div>
          <div className={styles.functionBlock}>
            <div className={styles.rememberMe}>
              <label className={styles.checkBoxContainer}>
                <span className={styles.labelText}>
                  Remember me
                </span>
                <input 
                  type="checkbox" 
                  name="radio" 
                  checked={rememberCheck} 
                  onChange={() => setRememberCheck(!rememberCheck)}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
            <Link className={styles.recoverPassword} to="/forgot-password">
              Recover password
            </Link>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              type="submit"
              disabled={
                Object.keys(errors).length ? true : false ||
                loading
              }
            >
              Sign In
              { loading && <Spinner /> }
            </Button>
          </div>
        </form>
      </div>
      <div className={styles.desktopBackground}></div>
    </div>
  );
}

export default Login;