import React, { ChangeEvent } from 'react';
import clsx from 'clsx'

import styles from './CustomInput.module.scss';

export interface InputProps {
	className?: string,
	label?: string,
	placeholder?: string,
	name?: string,
	type?: string,
	value?: string | number,
	errorMsg?: string,
	onChange?: Function,
	required?: boolean,
	error?: boolean,
	transformer?: Function,
	formatter?: Function,
	disabled?: boolean,
	onBlur?: Function,
	initialValue?: any,
	icon?: string,
	listenerKey?: string,
	listenerValue?: string | number,
	dateFormat?: string,
	timeFormat?: string
}

const CustomInput = (props: InputProps) => {
	const {
		className,
		label,
		type,
		name,
		placeholder,
		value,
		required,
		error,
		onChange,
		onBlur,
		disabled = false,
		initialValue,
		transformer,
		formatter,
		icon,
		errorMsg,
		listenerValue,
		listenerKey,
		dateFormat,
		timeFormat,
		...rest
	} = props;

	if (!props.onChange) {
		console.error(new Error(`Missing props onChange handler at CustomInput name=${name && name}`))
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			let value = e.target.value
			if (type === 'number') {
				props.onChange({
					name: name,
					value: value ? Number(value) : value
				})
				return
			}
			props.onChange({
				name: name,
				value: value
			})
		}
	}

	const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur({
				name: name,
				value: e.target.value
			})
		}
	}

	return (
		<div className={clsx(styles.root, className && className)}>
			<div
				className={clsx(
					styles.inputWrapper,
					!label && styles.nonePadding,
					errorMsg && styles.invalidInputWrapper
				)}
			>
				<input
					autoComplete="off"
					id={name}
					className={styles.input}
					type={type}
					placeholder={placeholder}
					name={name && name}
					value={value || ""}
					onChange={handleChange}
					onBlur={handleBlur}
					disabled={disabled}
					{...rest}
				/>
				{
					label &&
					<label htmlFor={name} className={styles.label}>
						{label}
					</label>
				}
				<div className={styles.line}></div>
				<div className={styles.activeLine}></div>
				{
					icon &&
					<div className={styles.icon}>
						<span className="material-icons-outlined">
							{icon}
						</span>
					</div>
				}
			</div>
			{
				errorMsg &&
				<span className={styles.error}>{errorMsg}</span>
			}
		</div>
	);
}

export default React.memo(CustomInput);