import { lazy } from 'react';

const PageModel = lazy(()=> import('../pages/PageModel'))
const PageModelAction = lazy(()=> import('../pages/PageModel/PageModelAction'))

const rootRouters = [
  {
    path: "/",
    name: "Dashboard",
    icon: "grid_view",
    inSideBar: true,
    component: () => "",
    exact: true,
  },
  {
    path: "/partner",
    name: "Danh sách đối tác",
    icon: "apartment",
    inSideBar: true,
    component: PageModel,
    model: "partner",
    exact: true
  },
  {
    path: "/partner/:type/:id?",
    name: "Đối tác",
    icon: "apartment",
    inSideBar: false,
    component: PageModelAction,
    model: "partner",
    exact: true
  },
  {
    path: "/staff",
    name: "Tài khoản",
    icon: "assignment_ind",
    inSideBar: true,
    component: PageModel,
    model: "staff",
    exact: true,
  }, 
  {
    path: "/staff/:type/:id?",
    name: "Tài khoản",
    icon: "assignment_ind",
    inSideBar: false,
    component: PageModelAction,
    model: "staff",
    exact: true,
  },
]

export default rootRouters
