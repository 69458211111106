import { MouseEvent, useEffect } from "react"
import clsx from 'clsx'
import { Link, useLocation } from "react-router-dom";

import styles from "./MenuItem.module.scss"

export interface MenuItemProps {
  className?: string,
  renderMenus?: Function,
  path: string,
  name: string,
  icon: string,
  childs?: Array<any>,
  open?: number | string,
  setOpen: Function,
  itemHeight: number,
  menuActive: boolean
}

const MenuItem = (props: MenuItemProps) => {
  const { 
    className,
    renderMenus,
    path,
    name,
    icon,
    childs,
    open,
    setOpen,
    itemHeight,
    menuActive
  } = props;
  const location = useLocation();
  

  useEffect(() => {
    if (
      location.pathname.split("/")[1] === path.split("/")[1] &&
      childs
    ) {
      setOpen(`/${path.split("/")[1]}`)
    }
  }, [])

  const handleExpand = (e: MouseEvent<HTMLAnchorElement>, id: number | string) => {
    e.preventDefault();
    setOpen(open === id ? null : id);
  }

  return (
    <li className={
      clsx(
        styles.root, 
        className && className,
        (
          open === path || 
          location.pathname === path
        ) && 
        styles.activeItem,
        menuActive && styles.menuActive
      )
    }>
       {
        childs && childs?.length ?
        <a href="/" onClick={(e) => handleExpand(e, path)}>
          <span className={clsx("material-icons-outlined", styles.icon)}>
            {icon}
          </span>
          <span className={styles.name}>{name}</span>
          <div className={styles.line}></div>
          <span 
            className={clsx(
              "material-icons-outlined", 
              styles.expand,
              open === path && styles.activeExpand
            )}
          >
            chevron_right
          </span>
        </a> :
        <Link to={path}>
          <span className={clsx("material-icons-outlined", styles.icon)}>
            {icon}
          </span>
          <span className={styles.name}>{name}</span>
          <div className={styles.line}></div>
        </Link>
      }
      {
        childs && childs?.length &&
        <div 
          className={styles.dropdown}
          style={{ height: `${open === path  ? childs.filter(item => item.inSideBar).length * itemHeight : 0}px` }}
        >
          { renderMenus(childs, true) }
        </div>
      }
      {
        !menuActive &&
        <div className={styles.tooltip}>
          { name }
        </div>
      }
    </li>
  )
}

export default MenuItem