import React, { ReactNode } from "react"
import clsx from 'clsx'

import styles from "./Alert.module.scss"

const getIcon = (severity: string) => {
  switch (severity) {
    case "error": return <span className="material-icons-outlined">error_outline</span>
    default: return <span className="material-icons-outlined">task_alt</span>
  }
}
const getClassName = (severity: string) => {
  switch (severity) {
    case "error": return styles.error
    default: return
  }
}

export interface AlertProps {
  className?: string,
  children: ReactNode,
  severity?: "success" | "error"
}

const Alert = (props: AlertProps) => {
  const { className, children, severity } = props;

  return (
    <div className={clsx(
      styles.root, 
      className && className,
      getClassName(severity)
    )}>
      { getIcon(severity) }
      { children }
    </div>
  )
}

export default Alert