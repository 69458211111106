import axiosAuth from "./axiosAuth"


const ChatService = {
    async getAllMessage(data){
        return axiosAuth.get('/page-messges/get-all',{params:data})
    },
    async sendMessage(conversationId: string, message: string){
        return axiosAuth.post(`/page-messges/${conversationId}/send-message/text?message=${message}`)
    },
    async getDetailMessage(conversationId: string, data: any){
        return axiosAuth.get(`page-messges/${conversationId}/get-details`, {params: data})
    },
    async getInfo(data:any){
        return axiosAuth.get('/page-messges/get-info',{params:data})
    },
    async sendImage(conversationId: string, file: FormData){
        return axiosAuth.post(`/page-messges/${conversationId}/send-message/image`,file)
    },
    async search(data:any){
        return axiosAuth.get('/page-messges/get-all/search',{params:{q:data}})
    },
    async getDetailCustomer(customerID:string){
        return axiosAuth.get(`/customers/${customerID}`)
    },
    async getListQuickReply(data:any){
        return axiosAuth.get(`/setting/quick-reply`,{params: data})
    },
    async addQuickReply(data:any){
        return axiosAuth.post('/setting/add-quick-reply',data)
    },
    async deleteQuickReply(quickReplyId: string){
        return axiosAuth.delete(`/setting/${quickReplyId}`)
    },
    async editQuickReply(quickReplyId: string, data:any){
        return axiosAuth.put(`/setting/${quickReplyId}/edit-quick-reply`,data)
    },
    async updateToken(data:any){
        return axiosAuth.post('/token/update-token',data)
    },
    async getToken(type:string){
        return axiosAuth.get(`/token`,{params:{type}});
    }
}

export default ChatService