import clsx from 'clsx'
import StorageService from '../../../services/StorageService';

import styles from "./LogoutButton.module.scss"

export interface LogoutButtonProps {
  className?: string
}

const LogoutButton = (props: LogoutButtonProps) => {
  const { className } = props;

  const handleLogout = () => {
    StorageService.token = {value: ""}
  }

  return (
    <li className={clsx(styles.root, className && className)}>
      <button onClick={handleLogout}>
        <span className={clsx("material-icons-outlined", styles.icon)}>
          logout
        </span>
        <span className={styles.name}>Đăng xuất</span>
      </button>
    </li>
  )
}

export default LogoutButton