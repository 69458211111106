import { FormEvent, useCallback, useState } from "react";
import validateField from "../utils/validateField";
import validateForm from "../utils/validateForm";

const useForm = ({
  initialValues,
  validateSchema,
}: any) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({})

  const handleChange = useCallback(({name, value}) => {
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }))
    if (validateSchema) {
      const { 
        valid, 
        newErrors 
      } = validateField({
        validateSchema, 
        name, 
        value,
        errors
      });

      setErrors(prevErrors => {
        if (!valid) {
          return {
            ...prevErrors,
            ...newErrors
          }
        } 
        const tempErrors = { ...prevErrors };
        delete tempErrors[name];
        return {
          ...tempErrors
        }
      });
    }
  }, [])

  const handleBlur = useCallback(({name, value}) => {
    if (validateSchema) {
      const { 
        valid, 
        newErrors 
      } = validateField({
        validateSchema, 
        name, 
        value,
        errors
      });

      if (!valid) {
        setErrors(prevErrors => {
          if (!valid) {
            return {
              ...prevErrors,
              ...newErrors
            }
          } 
          const tempErrors = { ...prevErrors };
          delete tempErrors[name];
          return {
            ...tempErrors
          }
        });
      }
    }
  }, [])

  const handleSubmit = (onSubmit: Function) => (e: FormEvent) => {
    e.preventDefault();

    if (validateSchema) {
      const { valid, newErrors } = validateForm({validateSchema, values});

      if (!valid) {
        setErrors(newErrors);
        return;
      }
    }

    setErrors({});

    onSubmit()
  }

  return { 
    values,
    errors, 
    handleChange, 
    handleBlur, 
    handleSubmit,
    setValues
  }
}

export default useForm;