import React, { ReactNode } from "react"
import clsx from 'clsx'

import styles from "./Button.module.scss"

export interface ButtonProps {
  className?: string,
  children: ReactNode | string,
  type?: "button" | "submit",
  onClick?: Function,
  variant?: "normal" | "light",
  disabled?: boolean
}

const Button = (props: ButtonProps) => {
  const { 
    className, 
    children,
    type = "button",
    onClick,
    variant = "normal",
    disabled = false
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if(onClick) {
      onClick(e)
    }
  }

  return (
    <button 
      className={clsx(
        styles.root, 
        className && className,
        variant === "light" && styles.light
      )}
      type={type}
      disabled={disabled}
      onClick={type === "submit" ? undefined : handleClick}
    >
      { children }
    </button>
  )
}

export default Button