const validateForm = ({validateSchema, values}) => {
  let valid = true;
  const newErrors = {};
  for (const key in validateSchema) {
    const value = values[key];
    const validation = validateSchema[key];

    if (validation?.required?.value && !value) {
      valid = false;
      newErrors[key] = validation?.required?.message;
    }
    if (value && validation?.isEmail && !validation?.isEmail?.isValid(value)) {
      valid = false;
      newErrors[key] = validation?.isEmail?.message;
    }
    if (value && validation?.isDate && !validation?.isDate?.isValid(value)) {
      valid = false;
      newErrors[key] = validation?.isDate?.message;
    }
    if (value && validation?.min && !validation?.min?.isValid(value)) {
      valid = false;
      newErrors[key] = validation?.min?.message;
    }
    if (value && validation?.max && !validation?.max?.isValid(value)) {
      valid = false;
      newErrors[key] = validation?.max?.message;
    }
  }
  return {
    valid,
    newErrors
  }
}

export default validateForm